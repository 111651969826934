'use strict';

/**
 * Internal dependencies
 */
import { $document } from '../globals.js';
import YITH_WCAF_Ajax from './ajax.js';

export default class YITH_WCAF_Set_Referrer {
	// container
	$container;

	// box opener
	$opener;

	// form
	$form;

	// token input
	$token;

	constructor( $container ) {
		if ( ! $container?.length ) {
			return;
		}

		this.$container = $container;
		this.$opener = this.$container.find( '.show-referrer-form' );
		this.$form = this.$container.find( 'form.referrer-form' );
		this.$token = this.$form.find( 'input[name="referrer_code"]' );

		this.init();
	}

	init() {
		this.$opener.on( 'click', this.onToggle.bind( this ) );
		this.$form.on( 'submit', this.onSubmit.bind( this ) );
	}

	onToggle( ev ) {
		ev.preventDefault();

		this.toggleForm();
	}

	onSubmit( ev ) {
		ev.preventDefault();

		const referrer = this.$token.val();

		if ( ! referrer ) {
			return false;
		}

		this.$form.addClass( 'processing' );
		this.setReferrer( referrer ).done( ( data ) => {
			this.$form.removeClass( 'processing' );

			this.afterSubmit( data );
		} );
	}

	afterSubmit( data ) {
		this.$container
			.find( '.woocommerce-error, .woocommerce-message' )
			.remove();

		if ( data?.data?.template ) {
			this.$form.before( data?.data?.template );
		}

		if ( data?.success ) {
			this.$form.slideUp();
			this.$token.prop( 'disabled', true );

			$document.trigger( 'yith_wcaf_referrer_set' );
		}
	}

	toggleForm() {
		this.$form.slideToggle();
	}

	setReferrer( referrer ) {
		return YITH_WCAF_Ajax.post.call(
			this.$container,
			'set_referrer',
			'set_referrer',
			{
				referrer,
			}
		);
	}
}
