'use strict';

/**
 * Internal dependencies
 */
import { $, $document } from '../globals.js';

class YITH_WCAF_Dependencies_Handler {
	// container
	$container;

	// fields;
	$fields;

	// dependencies tree.
	dependencies = {};

	constructor( $container ) {
		this.$container = $container;

		if ( ! this.$container?.length ) {
			return;
		}

		this.initFields();

		if ( ! this.$fields?.length ) {
			return;
		}

		this.initDependencies();
	}

	initFields() {
		this.$fields = this.$container.find( ':input' );
	}

	initDependencies() {
		this.buildDependenciesTree();

		if ( ! Object.keys( this.dependencies ).length ) {
			return;
		}

		this.handleDependencies();
	}

	buildDependenciesTree() {
		const self = this;

		this.$fields.closest( '[data-dependencies]' ).each( function () {
			const $field = $( this ),
				id = $field.attr( 'id' );

			if ( ! id ) {
				return;
			}

			const newBranch = {
				[ id ]: $field.data( 'dependencies' ),
			};

			self.dependencies = $.extend( self.dependencies, newBranch );
		} );

		// backward compatibility with plugin-fw
		this.$container.find( '[data-dep-target]' ).each( function () {
			const $container = $( this ),
				id = $container.data( 'dep-id' ),
				target = $container.data( 'dep-target' ),
				value = $container.data( 'dep-value' );

			if ( ! id || ! target || ! value ) {
				return;
			}

			const newBranch = {
				[ target ]: {
					[ id ]: value.toString().split( ',' ),
				},
			};

			self.dependencies = $.extend( self.dependencies, newBranch );
		} );
	}

	handleDependencies() {
		this.$fields.on( 'change', this.applyDependencies.bind( this ) );

		this.applyDependencies();
	}

	applyDependencies() {
		$.each( this.dependencies, ( field, conditions ) => {
			const $container = this.findFieldContainer( field );

			if ( ! $container.length ) {
				return;
			}

			const show = this.checkConditions( conditions );

			if ( show ) {
				$container?.fadeIn();
			} else {
				$container?.hide();
			}
		} );
	}

	findField( field ) {
		let $field = this.$container.find( `#${ field }` );

		if ( ! $field.length ) {
			$field = this.$container.find( `#${ field }_field` );
		}

		if ( ! $field.length ) {
			return false;
		}

		if ( ! $field.is( ':input' ) ) {
			$field = $field.find( ':input' );
		}

		return $field;
	}

	findFieldContainer( field ) {
		const $field = this.findField( field );

		if ( ! $field?.length ) {
			return false;
		}

		// first of all search for container by id.
		let $container = $field.closest( `#${ field }_container` );

		// if we couldn't find item with correct id, search for a .form-row
		if ( ! $container.length ) {
			$container = $field.closest( '.form-row' );
		}

		// finally, just assume closest table row is a valid container
		if ( ! $container.length ) {
			$container = $field.closest( '.yith-plugin-fw__panel__option' );
		}

		// if none of the previous worked, just fail to false
		if ( ! $container.length ) {
			return false;
		}

		return $container;
	}

	checkConditions( conditions ) {
		let result = true;

		$.each( conditions, ( field, condition ) => {
			const $field = this.findField( field );
			let fieldValue;

			if ( ! result || ! $field?.length ) {
				return;
			}

			if ( $field.first().is( 'input[type="radio"]' ) ) {
				fieldValue = $field.filter( ':checked' )?.val()?.toString();
			} else {
				fieldValue = $field?.val()?.toString();
			}

			if ( Array.isArray( condition ) ) {
				result = condition.includes( fieldValue );
			} else if ( typeof condition === 'function' ) {
				result = condition( fieldValue );
			} else if ( condition.indexOf( ':' ) === 0 ) {
				result = $field.is( condition );
			} else if ( condition.indexOf( '!:' ) === 0 ) {
				result = ! $field.is( condition.toString().substring( 1 ) );
			} else if ( condition.indexOf( '!' ) === 0 ) {
				result = condition.toString().substring( 1 ) !== fieldValue;
			} else {
				result = condition.toString() === fieldValue;
			}

			if ( typeof this.dependencies[ field ] !== 'undefined' ) {
				result =
					result &&
					this.checkConditions( this.dependencies[ field ] );
			}
		} );

		return result;
	}
}

export default function initDependencies( $container ) {
	// init container
	if ( ! $container?.length ) {
		$container = $document;
	}

	return new YITH_WCAF_Dependencies_Handler( $container );
}
