'use strict';

/**
 * Internal dependencies
 */
import { $, $document, labels } from '../globals.js';

const enhanceToggle = ( $field ) => {
		if ( $field.next( '.toggle' ).length ) {
			return;
		}

		const text_on = $field.data( 'text-on' ),
			text_off = $field.data( 'text-off' ),
			$toggle = $( '<span/>', {
				class: 'toggle',
				'data-text-on': text_on || labels.toggle_on,
				'data-text-off': text_off || labels.toggle_off,
			} );

		$field.after( $toggle ).addClass( 'enhanced' );
	},
	enhanceToggles = ( $container ) => {
		// init container
		if ( ! $container?.length ) {
			$container = $document;
		}

		const $toggles = $( '.yith-wcaf-toggle', $container ).not(
			'.enhanced'
		);

		if ( ! $toggles.length ) {
			return;
		}

		$toggles.each( function () {
			const $field = $( this );

			enhanceToggle( $field );
		} );
	};

// export utilities
export { enhanceToggle, enhanceToggles };
