'use strict';

/**
 * Internal dependencies
 */
import { $, $document, labels } from '../globals';
import { enhanceSelect, enhanceSelects } from './enhanced-selects';
import { enhanceToggle, enhanceToggles } from './enhanced-toggles';
import { enhanceAccordion, enhanceAccordions } from './enhanced-accordion';
import { enhanceUploader, enhanceUploaders } from './enhanced-uploaders';
import { enhanceDatepicker, enhanceDatepickers } from './enhanced-datepickers';
import initValidation from './validation';
import initDependencies from './dependencies';
import eyeSvg from '../../../images/eye.svg';

const initFields = ( $container ) => {
	// init container
	if ( ! $container?.length ) {
		$container = $document;
	}

	// init toggles
	enhanceToggles( $container );

	// init datepicker
	enhanceDatepickers( $container );

	// init accordions
	enhanceAccordions( $container );

	// init selects
	enhanceSelects( $container );

	// attach field
	enhanceUploaders( $container );

	// password field
	( () => {
		const $password = $( 'input[type="password"]', $container );

		if ( ! $password.length ) {
			return;
		}

		$password.each( function () {
			const $field = $( this );

			if ( $field.hasClass( 'enhanced' ) ) {
				return;
			}

			const $wrapper = $( '<div/>', {
					class: 'password-wrapper',
				} ),
				$toggleButton = $( '<a/>', {
					class: 'toggle-button click-to-show',
					role: 'button',
					href: '',
					html: eyeSvg,
					title: labels?.show_password_text || 'Show/Hide password',
				} );

			$field.wrap( $wrapper );
			$field.after( $toggleButton );
			$toggleButton.on( 'click', function ( ev ) {
				const type = $field.attr( 'type' ),
					isPassword = type === 'password',
					newType = isPassword ? 'text' : 'password',
					newClass = isPassword ? 'click-to-hide' : 'click-to-show';

				ev.preventDefault();

				$field.attr( 'type', newType );
				$toggleButton
					.removeClass( 'click-to-show click-to-hide' )
					.addClass( newClass );
			} );

			$field.addClass( 'enhanced' );
		} );
	} )();

	// type field
	( () => {
		const $type = $( '#invoice_type_field', $container );

		if ( ! $type.length || $type.hasClass( 'enhanced' ) ) {
			return;
		}

		let cnt = 1;

		$type
			.addClass( 'enhanced' )
			.find( 'label' )
			.each( function () {
				const $label = $( this ),
					$checkbox = $label.prev(),
					wrapperClass = `col${ cnt }`;

				$label
					.add( $checkbox )
					.wrapAll( `<div class="${ wrapperClass }">` );

				cnt++;
			} );
	} )();

	// init validation/dependencies on forms
	( () => {
		const $form = $( 'form', $container );

		if ( ! $form.length ) {
			return false;
		}

		initValidation( $form );
		initDependencies( $form );
	} )();

	// trigger after init
	$document.trigger( 'yith_wcaf_init_fields' );
};

// export utilities
export {
	enhanceDatepicker,
	enhanceSelect,
	enhanceToggle,
	enhanceAccordion,
	enhanceUploader,
	initFields,
	initDependencies,
	initValidation,
};
