'use strict';

/* global yith_wcaf, location */

/**
 * Internal dependencies
 */
import YITH_WCAF_Ajax from './ajax.js';

const setCookie = () => {
	if ( ! yith_wcaf.set_cookie_via_ajax || ! yith_wcaf.referral_var ) {
		return;
	}

	const urlParams = new URLSearchParams( location.search );

	if ( ! urlParams.has( yith_wcaf.referral_var ) ) {
		return;
	}

	YITH_WCAF_Ajax.get.call( null, 'set_cookie', '', {
		[ yith_wcaf.referral_var ]: urlParams.get( yith_wcaf.referral_var ),
	} );
};

// export utilities
export { setCookie };
