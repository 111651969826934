'use strict';

/**
 * Internal dependencies
 */
import { $ } from '../globals.js';
import { initFields } from './fields.js';
import YITH_WCAF_Modal from './yith-wcaf-modal.js';
import YITH_WCAF_Ajax from './ajax.js';

export default class YITH_WCAF_Ajax_Submit_Modal extends YITH_WCAF_Modal {
	// form to submit, if any
	$form = null;

	constructor( $opener, args ) {
		args = $.extend(
			{
				beforeSubmit: false,
				afterSubmit: false,
			},
			args || {}
		);

		super( $opener, args );
	}

	// event handler

	maybeOpenModal( content ) {
		// open modal.
		super.maybeOpenModal( content );

		// init fields once modal is completely shown
		this.$modal.promise().done( this.maybeInitForm.bind( this ) );
	}

	maybeInitForm() {
		// skip if we already processed form
		if ( this.$form?.length ) {
			return;
		}

		// init form, if any inside the modal
		const $form = this.$modal.find( 'form' );

		if ( ! $form.length ) {
			return;
		}

		// init fields
		initFields( $form );

		// init form submit handling
		$form.on( 'submit', this.maybeSubmitForm.bind( this ) );

		this.$form = $form;
	}

	async maybeSubmitForm( ev ) {
		ev.preventDefault();

		if ( typeof this.args?.beforeSubmit === 'function' ) {
			this.args.beforeSubmit.call( this );
		}

		try {
			const answer = await this.ajaxSubmit();

			if ( typeof this.args?.afterSubmit === 'function' ) {
				this.args.afterSubmit.call( this, answer );
			}
		} catch ( err ) {
			this.reportError( err );
			return;
		}

		setTimeout( () => {
			// close modal
			this.maybeCloseModal();

			// reload page
			window.location.reload();
		}, 3000 );
	}

	// error reporting

	reportError( error ) {
		const $errors = this.$form.find( '.errors' );

		if ( ! $errors.length ) {
			this.$form.prepend(
				$( '<p/>', {
					class: 'errors form-row woocommerce-invalid',
					text: error,
				} )
			);
		} else {
			$errors.text( error );
		}

		this.$form.closest( '.content' ).animate( {
			scrollTop: 0,
		} );
	}

	hideErrors() {
		this.$form.find( '.errors' ).remove();
	}

	// ajax handling

	ajaxSubmit() {
		const action = this.$form.data( 'action' ),
			nonce = this.$form.data( 'security' );

		return new Promise( ( resolve, fail ) => {
			if ( ! action || ! nonce ) {
				fail( '' );
			}

			this.hideErrors();

			YITH_WCAF_Ajax.post
				.call(
					this.$modal,
					action,
					nonce,
					new FormData( this.$form.get( 0 ) ),
					{
						processData: false,
						contentType: false,
					}
				)
				.done( ( data ) => {
					if ( data?.success ) {
						resolve( data?.data );
					} else {
						fail( data?.data?.message );
					}
				} )
				.fail( () => fail( '' ) );
		} );
	}
}
