'use strict';

/**
 * Internal dependencies
 */
import { $, $document } from '../globals.js';

const enhanceUploader = ( $anchor ) => {
		const $input = $anchor.parent().find( 'input[type="file"]' );

		if ( ! $input.length ) {
			return;
		}

		$anchor
			.on( 'click', function ( ev ) {
				ev.preventDefault();
				$input.trigger( 'click' );
			} )
			.addClass( 'enhanced' );

		$input.on( 'change', function ( ev ) {
			const files = ev.target.files,
				fileNames = [];

			let $fileList = $anchor.next( 'small.files-list' );

			for ( const i in files ) {
				if ( ! files.hasOwnProperty( i ) ) {
					continue;
				}

				fileNames.push( files[ i ].name );
			}

			if ( ! fileNames.length ) {
				if ( $fileList.length ) {
					$fileList.remove();
				}
			} else {
				if ( ! $fileList.length ) {
					$fileList = $( '<small/>', {
						class: 'files-list',
					} );

					$anchor.after( $fileList );
				}

				$fileList.text( fileNames.join( ', ' ) );

				if ( $anchor.hasClass( 'auto-submit' ) ) {
					const $form = $anchor.closest( 'form' );

					if ( ! $form.length ) {
						return;
					}

					$form.submit();
				}
			}
		} );
	},
	enhanceUploaders = ( $container ) => {
		// init container
		if ( ! $container?.length ) {
			$container = $document;
		}

		const $uploaders = $( '.yith-wcaf-attach-file', $container ).not(
			'.enhanced'
		);

		if ( ! $uploaders.length ) {
			return;
		}

		$uploaders.each( function () {
			const $field = $( this );

			enhanceUploader( $field );
		} );
	};

// export utilities
export { enhanceUploader, enhanceUploaders };
