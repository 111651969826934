'use strict';

/* global yith_wcaf */

/**
 * Internal dependencies
 */
import { $, labels } from './globals.js';
import { initFields } from './modules/fields';
import { setCookie } from './modules/set-cookie';
import YITH_WCAF_Link_Generator from './modules/yith-wcaf-link-generator';
import YITH_WCAF_Set_Referrer from './modules/yith-wcaf-set-referrer';
import YITH_WCAF_Copy_Button from './modules/yith-wcaf-copy-button';
import YITH_WCAF_Tooltip from './modules/yith-wcaf-tooltip';
import YITH_WCAF_Ajax_Submit_Modal from './modules/yith-wcaf-ajax-submit-modal';

class YITH_WCAF_Shortcodes {
	$container;

	constructor( $container ) {
		if ( ! $container.length ) {
			return;
		}

		// set container object.
		this.$container = $container;

		// start up.
		this.init();
	}

	init() {
		// init sequence.
		this.initTooltips();
		this.initCopyButtons();
		this.initLinkGenerator();
		this.initSetReferrer();
		this.initWithdrawModal();
		this.initSettings();

		// set cookie via ajax.
		this.setCookie();
	}

	initTooltips() {
		const $tooltips = $( '[data-tip]', this.$container );

		if ( ! $tooltips.length ) {
			return;
		}

		$tooltips.each( function () {
			new YITH_WCAF_Tooltip( $( this ) );
		} );
	}

	initCopyButtons() {
		this.$container.find( '.copy-trigger' ).each( function () {
			const $trigger = $( this ),
				$target = $trigger.parent().find( '.copy-target' );

			if ( ! $target.length ) {
				return;
			}

			new YITH_WCAF_Copy_Button( $trigger, $target );
		} );
	}

	initLinkGenerator() {
		const $linkGenerator = $( '.link-generator', this.$container );

		if ( ! $linkGenerator.length ) {
			return;
		}

		new YITH_WCAF_Link_Generator( $linkGenerator );
	}

	initSetReferrer() {
		const $setReferrer = $( '.set-referrer-wrapper', this.$container );

		if ( ! $setReferrer.length ) {
			return;
		}

		new YITH_WCAF_Set_Referrer( $setReferrer );
	}

	initWithdrawModal() {
		const $modalOpener = $( '#withdraw_modal_opener', this.$container );

		if ( ! $modalOpener.length ) {
			return;
		}

		new YITH_WCAF_Ajax_Submit_Modal( $modalOpener, {
			template: '#withdraw_modal',
			title: labels?.withdraw_modal_title,
			afterSubmit( answer ) {
				if ( answer?.template ) {
					const $content = this.$modal.find( '.content' ),
						$title = this.$modal.find( '.title' );

					$title.fadeOut();
					$content.fadeOut( () => {
						$content.empty().html( answer?.template ).fadeIn();
					} );
				} else {
					this.maybeCloseModal();
				}
			},
		} );
	}

	initSettings() {
		const $container = this.$container;

		if ( ! $container.length ) {
			return;
		}

		initFields( $container );
	}

	setCookie() {
		if ( ! yith_wcaf.set_cookie_via_ajax || ! yith_wcaf.referral_var ) {
			return;
		}

		setCookie();
	}
}

// init on document ready.
$( () => new YITH_WCAF_Shortcodes( $( '.yith-wcaf' ) ) );
