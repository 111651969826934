'use strict';

/**
 * Internal dependencies
 */
import { $, $document } from '../globals.js';

const enhanceAccordion = ( $field ) => {
		const $radio = $( '.accordion-radio', $field );

		if ( ! $radio.length ) {
			return;
		}

		$radio
			.on( 'change', function () {
				const $checked = $radio.filter( ':checked' ),
					$option = $checked.closest( '.accordion-option' ),
					$content = $option.find( '.accordion-content' );

				$( '.accordion-content', $field ).not( $content ).slideUp();
				$content.slideDown();
			} )
			.trigger( 'change' );

		$field.addClass( 'enhanced' );
	},
	enhanceAccordions = ( $container ) => {
		// init container
		if ( ! $container?.length ) {
			$container = $document;
		}

		const $accordion = $( '.yith-wcaf-accordion', $container ).not(
			'.enhanced'
		);

		if ( ! $accordion.length ) {
			return;
		}

		$accordion.each( function () {
			const $field = $( this );

			enhanceAccordion( $field );
		} );
	};

// export utilities
export { enhanceAccordion, enhanceAccordions };
