'use strict';

/* global navigator */

/**
 * Internal dependencies
 */
import { $, $document, $body, labels } from '../globals.js';

export default class YITH_WCAF_Copy_Button {
	// copy button
	$trigger;

	// event initiator
	$initiator;

	// target whose content should be copied
	target;

	constructor( $trigger, $target ) {
		if ( ! $trigger.length ) {
			return;
		}

		this.$trigger = $trigger;
		this.target = $target;

		this.init();
	}

	init() {
		this.$trigger.off( 'click' ).on( 'click', this.onClick.bind( this ) );
	}

	onClick( ev ) {
		ev.preventDefault();

		this.$initiator = $( ev.target );

		if ( this.copyContent( ev ) ) {
			this.outputNotification();
		}
	}

	copyContent( ev ) {
		const target = this.target;
		let $target;

		if ( typeof target === 'function' ) {
			$target = target( this.$trigger, ev );
		} else {
			$target = target;
		}

		if ( ! $target.length ) {
			return false;
		}

		if ( $target.is( 'input' ) ) {
			this.copyInputContent( $target );
		} else {
			this.copyAnyContent( $target );
		}

		return true;
	}

	copyInputContent( $target ) {
		if ( window.isSecureContext && navigator?.clipboard ) {
			return navigator.clipboard.writeText( $target.val() )
		}

		this.selectContent( $target );
		document.execCommand( 'copy' );
	}

	copyAnyContent( $target ) {
		if ( window.isSecureContext && navigator?.clipboard ) {
			return navigator.clipboard.writeText( $target.text() );
		}

		const $hidden = $( '<input/>', {
			val: $target.text(),
			type: 'text',
		} );

		$body.append( $hidden );

		this.selectContent( $hidden );
		document.execCommand( 'copy' );

		$hidden.remove();
	}

	selectContent( $target ) {
		if ( this.isIos() ) {
			$target.get( 0 ).setSelectionRange( 0, 9999 );
		} else {
			$target.select();
		}
	}

	outputNotification() {
		if (
			! $document.triggerHandler( 'yith_wcaf_hide_link_copied_alert' ) &&
			labels?.link_copied_message
		) {
			const $confirmBubble = $( '<span/>', {
				class: 'copy-confirmation',
				text: labels.link_copied_message,
			} );

			$confirmBubble.prependTo( this.$initiator ).fadeIn( 300, () => {
				setTimeout( () => {
					$confirmBubble.fadeOut( 300, () => {
						$confirmBubble.remove();
					} );
				}, 1000 );
			} );
		}
	}

	isIos() {
		return navigator.userAgent.match( /ipad|iphone/i );
	}
}
