'use strict';

/**
 * Internal dependencies
 */
import { $ } from '../globals.js';

export default class YITH_WCAF_Tooltip {
	// copy button
	$opener;

	// tooltip
	$tooltip;

	constructor( $opener ) {
		if ( ! $opener.length ) {
			return;
		}

		this.$opener = $opener.first();

		this.init();
	}

	init() {
		this.$opener
			.on( 'mouseenter', this.onMouseEnter.bind( this ) )
			.on( 'mouseleave', this.onMouseLeave.bind( this ) );
	}

	hasTooltip() {
		return this.$opener.find( '.tooltip' ).length;
	}

	onMouseEnter() {
		if ( ! this.hasTooltip() ) {
			this.attachTooltip();
		}

		this.showTooltip();
	}

	onMouseLeave() {
		this.hideTooltip();
	}

	attachTooltip() {
		if ( this.hasTooltip() ) {
			return;
		}

		const $tooltip = this.maybeBuildTooltip();
		this.$opener.append( $tooltip );
	}

	detachTooltip() {
		if ( ! this.$tooltip?.length ) {
			return;
		}

		this.$tooltip.detach();
	}

	showTooltip() {
		if ( ! this.$tooltip?.length ) {
			return;
		}

		return this.$tooltip.show().animate( {
			opacity: 1,
		} );
	}

	hideTooltip() {
		if ( ! this.$tooltip?.length ) {
			return;
		}

		return this.$tooltip.animate(
			{
				opacity: 0,
			},
			{
				complete: () => {
					this.$tooltip.hide();
					this.detachTooltip();
				},
			}
		);
	}

	maybeBuildTooltip() {
		if ( ! this.$tooltip?.length ) {
			const content = this.$opener.data( 'tip' );

			this.$tooltip = $( '<span/>', {
				html: content,
				class: 'tooltip',
			} );
		}

		return this.$tooltip;
	}
}
