'use strict';

/**
 * External dependencies
 */
import $ from 'jquery';

/* global yith_wcaf yith */

// these constants will be wrapped inside webpack closure, to prevent collisions

const $document = $( document ),
	$body = $( 'body' ),
	block = ( $el ) => {
		if ( typeof $.fn.block === 'undefined' ) {
			return false;
		}

		try {
			$el.block( {
				message: null,
				overlayCSS: {
					background: '#fff',
					opacity: 0.6,
				},
			} );

			return $el;
		} catch ( e ) {
			return false;
		}
	},
	unblock = ( $el ) => {
		if ( typeof $.fn.unblock === 'undefined' ) {
			return false;
		}

		try {
			$el.unblock();
		} catch ( e ) {
			return false;
		}
	},
	confirm = ( title, message, args ) => {
		return new Promise( ( resolve, reject ) => {
			// if can't display modal, accept by default
			if ( typeof yith?.ui?.confirm === 'undefined' ) {
				reject( new Error( 'Missing yith.ui utilities' ) );
			}

			const options = {
				title: title || labels.generic_confirm_title,
				message: message || labels.generic_confirm_message,
				...args,
			};

			options.onConfirm = () => resolve( true );
			options.onCancel = reject;

			yith.ui.confirm( options );
		} );
	};
let ajaxUrl, nonces, labels;

if ( typeof yith_wcaf !== 'undefined' ) {
	ajaxUrl = yith_wcaf?.ajax_url;
	nonces = yith_wcaf?.nonces;
	labels = yith_wcaf?.labels;
}

export {
	$,
	$document,
	$body,
	block,
	unblock,
	confirm,
	ajaxUrl,
	nonces,
	labels,
};
