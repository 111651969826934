'use strict';

/**
 * Internal dependencies
 */
import { $ } from '../globals.js';
import YITH_WCAF_Copy_Button from './yith-wcaf-copy-button.js';
import YITH_WCAF_Ajax from './ajax.js';

export default class YITH_WCAF_Link_Generator {
	// container
	$container;

	// source input
	$source;

	// username input, if any
	$username;

	// destination input
	$destination;

	// affiliate token
	token;

	// query string for token
	token_var;

	// affiliate id, if any
	affiliate;

	// timeout used for debouncing
	timeout;

	// timout interval for debouncing
	timeoutInterval = 500;

	constructor( $container ) {
		if ( ! $container?.length ) {
			return;
		}

		this.$container = $container;
		this.token = this.$container.data( 'token' );
		this.token_var = this.$container.data( 'token-var' );
		this.affiliate = this.$container.data( 'affiliate' );

		this.init();
	}

	init() {
		this.$source = $( '.origin-url', this.$container );
		this.$destination = $( '.generated-url', this.$container );
		this.$username = $( '.username', this.$container );

		if ( ! this.$source.length || ! this.$destination.length ) {
			return;
		}

		this.initActions();
		this.initCopyButton();
	}

	initActions() {
		this.$source
			.on( 'change keyup', this.onChange.bind( this ) )
			.trigger( 'change' );
	}

	initCopyButton() {
		this.$container.find( '.copy-trigger' ).each( function () {
			const $trigger = $( this ),
				$target = $trigger.parent().find( '.copy-target' );

			if ( ! $target.length ) {
				return;
			}

			new YITH_WCAF_Copy_Button( $trigger, $target );
		} );
	}

	onChange() {
		// debounce
		if ( this.timeout ) {
			clearTimeout( this.timeout );
		}

		if ( ! this.$source.val() ) {
			return;
		}

		this.timeout = setTimeout(
			this.updateUrl.bind( this ),
			this.timeoutInterval
		);
	}

	async updateUrl() {
		const base = this.$source.val();

		let url;

		if ( this.token_var && this.token ) {
			url = this.calculateUrl( base, this.token_var, this.token );
		} else {
			const user = this.$username.val(),
				affiliate = this.affiliate;

			url = await this.requestUrl( base, user, affiliate );
		}

		this.$destination.val( url );
	}

	calculateUrl( base, token_var, token ) {
		let url;

		try {
			url = new URL( base );

			// if passed url's origin is different from current one, return.
			if ( url.origin !== window.location.origin ) {
				return '';
			}

			// append referral param.
			url.searchParams.set( token_var, token );
		} catch ( e ) {
			return '';
		}

		return url.toString();
	}

	requestUrl( base, user, affiliate_id ) {
		return new Promise( ( resolve ) => {
			YITH_WCAF_Ajax.get
				.call(
					this.$container,
					'get_referral_url',
					'get_referral_url',
					{
						base,
						user,
						affiliate_id,
					}
				)
				.done( ( data ) => {
					let url;

					if ( data?.success ) {
						url = data?.data?.url;
					} else {
						url = '';
					}

					resolve( url );
				} )
				.fail( () => resolve( '' ) );
		} );
	}
}
