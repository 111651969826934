'use strict';

/**
 * Internal dependencies
 */
import { $, block, unblock, nonces, ajaxUrl } from '../globals.js';

const request = function ( method, action, security, params, args ) {
		// retrieve wrapper as current context.
		const $wrapper = $( this );

		if ( params instanceof FormData ) {
			params.append( 'action', `yith_wcaf_${ action }` );
			params.append(
				'security',
				nonces?.[ security ] ? nonces?.[ security ] : security
			);
		} else {
			params = {
				action: `yith_wcaf_${ action }`,
				security: nonces?.[ security ]
					? nonces?.[ security ]
					: security,
				...params,
			};
		}

		const ajaxArgs = {
			url: ajaxUrl,
			data: params,
			method,
			beforeSend: () => $wrapper.length && block( $wrapper ),
			complete: () => $wrapper.length && unblock( $wrapper ),
			...args,
		};

		return $.ajax( ajaxArgs );
	},
	get = function ( ...params ) {
		return request.call( this, 'get', ...params );
	},
	post = function ( ...params ) {
		return request.call( this, 'post', ...params );
	};

export default { request, get, post };
