'use strict';

/**
 * Internal dependencies
 */
import { $, $document, ajaxUrl } from '../globals.js';

const enhanceSelect = ( select, args ) => {
		if ( typeof $.fn.selectWoo === 'undefined' ) {
			return;
		}

		const allowClear = !! select.data( 'allow_clear' ),
			placeholder = select.data( 'placeholder' ),
			minimumInputLength = select.data( 'minimum_input_length' ),
			action = select.data( 'action' ),
			ajax = !! action;

		let config = {
			allowClear,
			placeholder,
			minimumInputLength: minimumInputLength || 3,
		};

		if ( ajax ) {
			config.ajax = {
				url: ajaxUrl,
				dataType: 'json',
				delay: 250,
				data( params ) {
					return {
						term: params.term,
						action,
						security: select.data( 'security' ),
						exclude: select.data( 'exclude' ),
						include: select.data( 'include' ),
						limit: select.data( 'limit' ),
					};
				},
				processResults( data ) {
					const terms = [];
					if ( data ) {
						$.each( data, function ( id, text ) {
							terms.push( { id, text } );
						} );
					}
					return {
						results: terms,
					};
				},
				cache: true,
			};
		}

		config = $.extend( config, args || {} );

		try {
			select.selectWoo( config ).addClass( 'enhanced' );
		} catch ( e ) {
			// skip to next.
		}
	},
	enhanceSelects = ( $container ) => {
		if ( typeof $.fn.selectWoo === 'undefined' ) {
			return;
		}

		// init container
		if ( ! $container?.length ) {
			$container = $document;
		}

		const fieldToProcess = $(
			'.yith-wcaf-enhanced-select',
			$container
		).not( '.enhanced' );

		fieldToProcess.each( function () {
			const select = $( this );

			enhanceSelect( select );
		} );
	};

export { enhanceSelect, enhanceSelects };
