'use strict';

/**
 * Internal dependencies
 */
import { $, $document } from '../globals.js';

const enhanceDatepicker = ( field ) => {
		if ( typeof $.fn.datepicker === 'undefined' ) {
			return;
		}

		const format = field.data( 'format' ),
			numberOfMonths = field.data( 'number-of-months' ),
			maxDate = field.data( 'max-date' ),
			minDate = field.data( 'min-date' ),
			altField = field.data( 'altfield' ),
			altFormat = field.data( 'altformat' ),
			config = {
				dateFormat: format || 'yy-mm-dd',
				numberOfMonths: numberOfMonths || 1,
				maxDate: maxDate || null,
				minDate: minDate || null,
				altField: altField ? field.next( altField ).get( 0 ) : null,
				altFormat: altFormat || '',
				beforeShow( input, inst ) {
					inst?.dpDiv
						?.addClass( 'yith-wcaf-datepicker' )
						?.addClass( 'yith-plugin-fw-datepicker-div' );
				},
				onClose( input, inst ) {
					inst?.dpDiv
						?.removeClass( 'yith-wcaf-datepicker' )
						?.removeClass( 'yith-plugin-fw-datepicker-div' );
				},
			};

		try {
			field.datepicker( config ).addClass( 'enhanced' );
		} catch ( e ) {
			// skip to next field.
		}
	},
	enhanceDatepickers = ( $container ) => {
		if ( typeof $.fn.datepicker === 'undefined' ) {
			return;
		}

		// init container
		if ( ! $container?.length ) {
			$container = $document;
		}

		const fieldToProcess = $(
			'.yith-wcaf-enhanced-date-picker',
			$container
		)
			.add( '.date-picker-field', $container )
			.add( '.date-picker', $container )
			.not( '.enhanced' );

		fieldToProcess.each( function () {
			const field = $( this );

			enhanceDatepicker( field );
		} );
	};

export { enhanceDatepicker, enhanceDatepickers };
